import React from "react";
import { connect } from "react-redux";
import "../../App.css";
import Navbar from "../../components/Navbar";
import NetworkService from "../../services/NetworkService";
import Modal from "../../components/Modal";
import helper from "../../services/helper";
import i18n from "../../services/i18n";
import moment from "moment";
import queryString from "query-string";
import ListPagination from "../ListPagination";
import AdvancedSearchArticle from "./AdvancedSearchArticle";

const mapStateToProps = function (state) {
  return {
    session: state.session,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setArticle: (article) =>
      dispatch({
        type: "SET_ARTICLE",
        article: article,
      }),
  };
};

let searchQuery;

class Article extends React.Component {
  _isMounted = false;

  constructor(props) { 
    super(props);

    searchQuery = this.props.location?.search;

    this.state = {
      articles: { items: [], totalCount: 0 },
      isOpen: false,
      deletearticleid: "",
      articleLoading: false,
      search: {
        freeText: queryString.parse(searchQuery).search || "",
        supplier: queryString.parse(searchQuery).supplier || "",
        claimName: queryString.parse(searchQuery).claimname || "",
        partnerid: queryString.parse(searchQuery).partnerid || "",
      },
      editArticleNumber: false,
      selectedArticle: { partnerarticlenumber: "" },
      claims: [],
      partners: [],
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onClose = this.onClose.bind(this);
    this._deleteArticle = this._deleteArticle.bind(this);
    this._searchChange = this._searchChange.bind(this);
    this._searchSubmit = this._searchSubmit.bind(this);
    this._searchKeyDown = this._searchKeyDown.bind(this);
    this._saveArticleNumber = this._saveArticleNumber.bind(this);
    this._articleNumberChange = this._articleNumberChange.bind(this);
    this._fetchArticles = this._fetchArticles.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchArticles();
  }

  componentDidUpdate() {
    if (searchQuery !== this.props.location.search) {
      searchQuery = this.props.location.search;
      this._fetchArticles();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleModal(e, articleid) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen,
      deletearticleid: articleid,
    });
  }

  onClose() {
    this.setState({
      isOpen: !this.state.isOpen,
      deletearticleid: null,
    });
  }

  _fetchArticles() {
    var that = this;

    that.setState({ articleLoading: true }, function () {
      NetworkService.getArticles(
        searchQuery,
        that.props.session,
        function (articles) {
          if (that._isMounted) {
            that.setState({ articles: articles, articleLoading: false });
          }
        },
        function (err) {
          console.log(err,searchQuery);
          that.setState({ articleLoading: false });
        }
      );
    });
  }

  _deleteArticle() {
    var that = this;
    NetworkService.deleteArticle(
      that.state.deletearticleid,
      that.props.session,
      function (article) {
        that.props.push(0);
      },
      function (err) {
        console.log(err);
      }
    );
  }

  _renderArticleRows() {
    // [OV] 'hasAccess' meaning user access. Required: "1,1" = read, write, "1,0" = read.
    var hasAccess = helper.checkAccess(this.props.session.scope, "ARTICLE", 1, 1);
    let rows = [];
    if (
      this.state.articles &&
      this.state.articles.items &&
      this.state.articles.items.length > 0
    ) {
      rows = this.state.articles.items.map((item, i) => {
        let classes = "";

        if (hasAccess) {
          // Rows are clickable if user has write access
          classes += " tr-clickable";
        }

        if (item.archived) {
          classes += " table-warning";
        }

        return (
          <tr
            key={i}
            className={classes}
            onClick={
              // Clicking on a row will open the article details
              // Only works if user has write access
              (e) => (hasAccess ? this._addArticle(item) : "")
            }
          >
            <td>
              {<span style={item.partnerid?{backgroundColor: 'yellow'}:{}}>{item.articlenumber}</span>}
              {/* [2023-05-31 Not used anymore] this._renderPartnerArticleNumber(item) */}
              {item.archived ? (
                <i
                  className="fa fa-warning ml-3"
                  data-toggle="tooltip"
                  title={i18n.t("article_archived", {
                    archived: moment(item.archived).format("YYYY-MM-DD"),
                  })}
                ></i>
              ) : (
                ""
              )}
            </td>
            <td>{item.articledescription}</td>
            <td>{item.category}</td>
            <td>{item.unitname}</td>
            <td>{item.height}</td>
            <td>{item.width}</td>
            <td>{item.depth}</td>
            {/* <td>{item.articlegroupname}</td> */}
            {/* <td>{item.articlegroups?item.articlegroups[0]?.name:"No article groups"}</td> */}
            <td>{item.articlegroups?.map((group) => {
                  return <div key={group.articlegroupid}>[{group.certificatename}] {group.name}</div>
                })}
            </td>
            {/* [OV] Removed Delete button
            <td>
              {hasAccess ? (
                <button
                  className="pull-right btn btn-danger"
                  onClick={(e) => this.toggleModal(e, item.articleid)}
                >
                  {i18n.t("common_button_delete")}
                </button>
              ) : null}
            </td> */}
          </tr>
        );
      });
    } else if (this.state.articleLoading) {
      return (
        <tr>
          <td colSpan="8" className="loading-td">
            <i className="fa fa-spin fa-spinner"></i>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td colSpan="8">{i18n.t("article_message_nodata")}</td>
        </tr>
      );
    }

    return rows;
  }

  // [OV] 'handle unit names' as admin
  _handleUnitNames() {
    this.props.push("/article/unitname");
  }

  _addArticle(item) {
    // Check if user is a partner
    // In that case save to partner
    let partner = null;
    if(this.props.session &&
      this.props.session.scope &&
      this.props.session.scope.partner) {
        // The user is a partner
        partner = this.props.session.scope.partner;
    }
    console.log("_addArticle: ", item, partner)
    if ((item !== undefined) && (item !== null)) {
      this.props.setArticle({
        articleid: item.articleid,
        articlegroupid: item.articlegroupid,
        category: item.category,
        articlenumber: item.articlenumber,
        articledescription: item.articledescription,
        width: item.width,
        height: item.height,
        depth: item.depth,
        unitid: item.unitid,
        unitname: item.unitname,
        partnerid: partner?partner.partnerid:null,
        partnerdescription: partner?partner.partnerdescription:null,
        articlegroups:item.articlegroups||[],
      });
      // [OV] 'push' from props to this class component
      this.props.push(`/article/detail/${item.articleid}`);
    } else {
      // New article: id is null
      this.props.setArticle({
        articleid: null,
      })
      // Set partnerid if user is a partner
      if(partner) {
        this.props.setArticle({
          partnerid:  partner.partnerid,
          partnerdescription: partner.partnerdescription,
        });
      }
      this.props.push("/article/add");
    }
  }

  _searchChange(event) {
    let search = this.state.search;
    switch (event.target.id) {
      case "order-search-freetext":
        search.freeText = event.target.value;
        break;
      case "order-search-supplier":
        search.supplier = event.target.value;
        break;
      case "order-search-claimname":
        search.claimName = event.target.value;
        break;
      case "article-search-partner":
        search.partnerid = event.target.value;
        break;
      default:
        throw new Error("No matching element id found");
    }

    this.setState({ search: search });
  }

  _searchSubmit() {
    let query = queryString.parse(searchQuery);
    query.search = this.state.search.freeText;
    query.supplier = this.state.search.supplier;
    query.claimname = this.state.search.claimName;
    query.partnerid = this.state.search.partnerid;

    query.page = 1;

    // [OV] Switched 'push' from 'connected-react-router' to 'props.push'
    this.props.push(this.props.location.pathname + "?" + queryString.stringify(query));
  }

  _searchKeyDown(event) {
    if (event.key === "Enter") {
      this._searchSubmit();
    }
  }

  //For partners, render custom article number
  // [2023-05-31 Not used anymore]
  _renderPartnerArticleNumber(article) {
    if (
      this.props.session &&
      this.props.session.scope &&
      this.props.session.scope.partner
    ) {
      //Ugly clone in order to avoid mutation
      let selectedArticle = JSON.parse(JSON.stringify(article));
      return (
        <>
          <p>
            {article.partnerarticlenumber}{" "}
            <a
              href="#"
              onClick={() => {
                this.setState({
                  selectedArticle: selectedArticle,
                  editArticleNumber: true,
                });
              }}
            >
              <i className="fa fa-edit" />
            </a>
          </p>
        </>
      );
    } else {
      return null;
    }
  }

  //Modal for creating custom article number
  _renderArticleNumberModal() {
    return (
      <input
        type="text"
        id="order-search-freetext"
        className="form-control"
        value={this.state.selectedArticle.partnerarticlenumber}
        onChange={this._articleNumberChange}
        placeholder={i18n.t("article_common_number")}
      />
    );
  }

  //Save partner custom article number
  _saveArticleNumber() {
    let article = this.state.selectedArticle;
    let partnerArticleNumber = {
      articleid: article.articleid,
      partnerid: this.props.session.scope.partner,
      articlenumber: article.partnerarticlenumber,
    };
    NetworkService.createPartnerArticleNumber(
      partnerArticleNumber,
      this.props.session,
      (value) => {
        this.setState({ selectedArticle: {}, editArticleNumber: false });
        this._fetchArticles();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  _articleNumberChange(event) {
    let selectedArticle = this.state.selectedArticle;
    selectedArticle.partnerarticlenumber = event.target.value;
    this.setState({ selectedArticle: selectedArticle });
  }

  render() {
    const title = i18n.t("common_articles");
    // [OV] 'hasAccess' meaning write access required to manipulate articles
    var hasAccess = helper.checkAccess(this.props.session.scope, "ARTICLE", 1, 1);
    var isAdmin = (this.props.session.scope.role === "Administrator");

    return (
      <div id="page-content-wrapper">
        <Navbar title={title} />
        <div className="container mt-5">
          <div className="col">
            {// Only show 'add article' button if user has write access or is logged in as a partner
              hasAccess || (this.props.session &&
                this.props.session.scope &&
                this.props.session.scope.partner
              ) ? (
                <div className="col-md-12 ">
                  <button
                    className="btn btn-outline-secondary col-md-4"
                    onClick={(e) => {this._addArticle(null)}}
                  >
                    {" "}
                    <i className="fa fa-plus"></i> {i18n.t("article_button_add")}
                  </button>
                  &nbsp;&nbsp;
                  {/* Only admin can handle article units */}
                  {isAdmin?(
                    <button
                      className="btn btn-outline-secondary col-md-6"
                        onClick={
                          // Add 'handle unit names' as admin
                          (e) => this._handleUnitNames()
                        }
                      >
                        {" "}
                        <i className="fa fa-pencil"></i> {i18n.t("article_handle_unit_names")}

                    </button>
                  ):""}
                </div>
              ) : ""
            }

            <AdvancedSearchArticle
              search={this.state.search}
              _searchSubmit={this._searchSubmit.bind(this)}
              _searchKeyDown={this._searchKeyDown.bind(this)}
              _searchChange={this._searchChange.bind(this)}
            ></AdvancedSearchArticle>
          </div>

          <table className="table table-striped mt-4 table-responsive-sm">
            <thead>
              <tr>
                <th>{i18n.t("article_common_number")}</th>
                <th>{i18n.t("article_common_description")}</th>
                <th>{i18n.t("article_common_category")}</th>
                <th>{i18n.t("article_common_unit")}</th>
                <th>{i18n.t("article_common_height")}</th>
                <th>{i18n.t("article_common_width")}</th>
                <th>{i18n.t("article_common_depth")}</th>
                {/* <th>{i18n.t("article_common_articlegroup")}</th> */}
                <th>{i18n.t("article_common_articlegroups")}</th>
              </tr>
            </thead>
            <tbody>{this._renderArticleRows()}</tbody>
          </table>

          <ListPagination
            location={this.props.location}
            push={(dest) => this.props.push(dest)}
            itemCount={this.state.articles ? this.state.articles.totalCount : 0}
          />
          </div>
          <Modal
            show={this.state.editArticleNumber}
            onClose={() => {
              this.setState({ selectedArticle: {}, editArticleNumber: false });
            }}
            onOk={this._saveArticleNumber}
          >
            {this._renderArticleNumberModal()}
          </Modal>
          <Modal
            show={this.state.isOpen}
            onClose={this.onClose}
            onOk={this._deleteArticle}
          >
          {i18n.t("article_message_delete")}
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
