import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import NetworkService from '../../services/NetworkService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Autosuggest from 'react-autosuggest';

import i18n from "../../services/i18n";
import helper from '../../services/helper'
import Decimal from 'decimal.js';

const mapStateToProps = function (state) {   
    return {
        session: state.session      
    }
}

let uniqueTimeout;
const getSuggestionValue = suggestion => suggestion.articlenumber + " - " + suggestion.articledescription;

const renderSuggestion = suggestion => (
    <div className="autocomplete-list">
        {suggestion.certificatename + " - " + suggestion.articlenumber + " - " + suggestion.articledescription}
    </div>
);

class EditDelivery extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)
            this.state = {
                articleselection:[],
                supplierselection: null,
                value: '',
                suggestions:[],
                certificates:[],
                claims: [],
                delivery:this.props.delivery?JSON.parse(JSON.stringify(this.props.delivery)):{                      
                    deliverynumber:"",
                    supplierid:"",
                    siteid:"",
                    reference:"",                    
                    registrationdate: moment().toDate(),
                    deposits:[]            
                },
                deposit:{                      
                    amount:"",
                    mixpercent:"",                    
                    location:"",
                    articleid:"",                         
                    claimid:"",
                    cubicmeter:"",
                    numberofarticle:""
                },
                method: "directamount",
                volume:"",
                codeTaken: false,
            }
    }

    componentDidMount() { 
        this._isMounted = true;         
        var that = this;
        NetworkService.getSimpleCertificatesList(that.props.session, function (certificate) {
            that.setState({certificates:certificate})
            NetworkService.getSimpleSuppliersList(that.props.session, function (supplier) {
                //Sort suppliers by name
                supplier = helper.sortArray(supplier, "name");
                if (that._isMounted) {
                    that.setState({suppliers:supplier})
                    if(that.state.delivery.supplierid){
                        that._handleSupplierChange(that.state.delivery.supplierid);
                    }
                }
            }, function (err) {
                console.log(err);
            });
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleSitesList(that.props.session, function (site) {    
            //Sort sites by name
            site = helper.sortArray(site, "name");

            if (that._isMounted) {               
                that.setState({sites:site})            
            }
        }, function (err) {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _cancel(){                
        this.props.onClose();
    }

    createSiteItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_site")}</option>);
        if(this.state.sites && this.state.sites.length>0){
            for (let i = 0; i < this.state.sites.length; i++) {
                items.push(<option key={"site-"+i} value={this.state.sites[i].siteid}>{this.state.sites[i].name}</option>);
            }
        }
        return items;
    }
    
    createSupplierItems() {              
        let items = [];
        items.push(<option key="supplierlist" value="">{i18n.t("common_select_supplier")}</option>);
        if(this.state.suppliers && this.state.suppliers.length>0){
            for (let i = 0; i < this.state.suppliers.length; i++) {
                items.push(<option key={"supplier-"+i} value={this.state.suppliers[i].supplierid}>{this.state.suppliers[i].name}</option>);
            }
        }
        return items;
    }

    createArticleGroupItems() {
        let items = [];
        items.push(<option key="articlegrouplist" value="">{i18n.t("common_select_articlegroup")}</option>);
        if(this.state.articlegroups && this.state.articlegroups.length>0){
            for (let i = 0; i < this.state.articlegroups.length; i++) {                
                items.push(<option key={"art_itm_"+i} value={this.state.articlegroups[i].articlegroupid}>{this.state.articlegroups[i].name}</option>);
            }
        }
        return items;
    }

    createArticleItems() {
        let items = [];        
        items.push(<option key="articlelistinit" value="">{i18n.t("common_select_article")}</option>);      
        if(this.state.articles){     
            var filteredArray = [];   
            if(this.state.deposit.articlegroupid){
                filteredArray = this.state.articles.filter(a => a.articlegroupid === parseInt(this.state.deposit.articlegroupid));
            } else {
                filteredArray = this.state.articles;
            }
            var result = filteredArray.reduce((r, a) => {           
                r[a.articlegroupid] = [...r[a.articlegroupid] || [], a];
                return r;
            }, {});           

            var keysArr = Object.keys(result);
            for(let j=0; j<keysArr.length; j++){
                if(this.state.articlegroups){
                   var articlegroup =this.state.articlegroups.filter(x => x.articlegroupid === parseInt(keysArr[j]));                                
                    var articles =result[keysArr[j]];              
                    items.push(<optgroup key={articlegroup[0].articlegroupid} label={articlegroup[0].name}></optgroup>)
                    for (let i = 0; i < articles.length; i++) {
                        items.push(<option key={"art-"+i+articles[i].articleid} value={articles[i].articleid}>{articles[i].articlenumber + " - " + articles[i].articledescription}</option>);
                    }
                }                           
            }
        }
        return items;
    }

    _handleRegistrationDateChange = date => {
        let delivery = Object.assign({},this.state.delivery);  
        delivery.registrationdate = date;        
        this.setState({
            delivery: delivery           
        });
    };

    _handleChange(type, val){ 
        if(val< 0){        
            val = 0;            
        }  
        
        var mxprelem = document.getElementById("mxprelem");
        if(mxprelem.classList.contains('error')){                
            mxprelem.classList.remove('error');                
        }

        var amtelem = document.getElementById("amtelem");
        if(amtelem.classList.contains('error')){                
            amtelem.classList.remove('error');                
        }

        var claimelem = document.getElementById("claimelem");
        if(claimelem.classList.contains('error')){
            claimelem.classList.remove('error');
        }

        let deposit = Object.assign({},this.state.deposit);             
        deposit[type] = val;        

        if(type==='mixpercent' && val){
            if(val > 100){
                val = 100;                   
                deposit[type] = val;          
            }  
            if(this.state.deposit.cubicmeter){
                var cal_amt = new Decimal(val * this.state.deposit.cubicmeter);
                cal_amt = cal_amt.dividedBy(100);             
                deposit.amount = cal_amt.toFixed(5);      
            }            
            if(this.state.volume && this.state.deposit.numberofarticle){
                var cal_amount = new Decimal(val * this.state.volume * this.state.deposit.numberofarticle);       
                cal_amount = cal_amount.dividedBy(100);       
                deposit.amount = cal_amount.toFixed(5);
            } 
        }

        if (type === 'claim') {
            deposit.claimid = val;
        }

        this.setState({
            deposit: deposit           
        });                   
    }

    _handleDeliveryChange(type, val){
        let delivery = Object.assign({},this.state.delivery);             
        delivery[type] = val;           
        this.setState({
            delivery: delivery           
        });                   
    }

    _handleArticleChange(val, articlegroupid = null){
        var that = this;

        if (val) {
			let articleid = val;
			var artwarn = document.getElementById("artwarn");

            if (artwarn.classList.contains('err-visible')) {
                artwarn.classList.remove('err-visible');                
            }

            let deposit = Object.assign({},that.state.deposit);

            // Set article
            deposit.articleid = articleid;
            let article = {};

            if(this.state.articleselection.length > 0){
                if(articlegroupid){
                    article = this.state.articleselection.find(article => (article.articleid === parseInt(articleid) && article.articlegroupid === parseInt(articlegroupid)));
                } else {
                    article = this.state.articleselection.find(article => (article.articleid === parseInt(articleid)));
                }
            } else {
                if(articlegroupid){
                    article = this.state.articles.find(article => (article.articleid === parseInt(articleid) && article.articlegroupid === parseInt(articlegroupid)));
                } else {
                    article = this.state.articles.find(article => (article.articleid === parseInt(articleid)));

                }
            }

            var vol = (article.width * article.height * article.depth)/1000000000;

            if (that.state.method === "article") {
                if (that.state.deposit.numberofarticle) {
                    deposit.cubicmeter = vol * that.state.deposit.numberofarticle;   
                }
            }

            // Set article group
            deposit.articlegroupid = article.articlegroupid;

            // Filter claims
            let supplier = that.state.supplierselection;

            let claims = supplier.claims.filter(claim => claim.articlegroupids.indexOf(article.articlegroupid) > -1);

            that.setState({
                deposit: deposit,
                claims: claims,
                volume: vol                                     
            }, function(){
                let deposit = Object.assign({},that.state.deposit);
                if(deposit.mixpercent && that.state.deposit.numberofarticle && that.state.volume){
                    var cal_amount = new Decimal(deposit.mixpercent * that.state.volume * that.state.deposit.numberofarticle);
                    cal_amount = cal_amount.dividedBy(100);
                    deposit.amount = cal_amount.toFixed(5);
                    that.setState({           
                        deposit: deposit,                                           
                    });
                }
            });
        } 
    }

    _handleSupplierChange(val){
        var that = this;                   

        var delivery = Object.assign({},that.state.delivery);             
        var deposit = Object.assign({}, that.state.deposit);

        // Set supplier
        delivery.supplierid = val;

        // Reset deposit values
        deposit.articleid = null;
        deposit.articlegroupid = '';
        deposit.claimid = '';

        // Update state
        that.setState({
            delivery: delivery,
            deposit: deposit,
            value: ''
        });

        NetworkService.getSupplierPromise(val, that.props.session)
            .then(function(supplier) {
                var articlegroups = supplier.articlegroup;

                // Remove duplicate article groups
                articlegroups = articlegroups.filter((group, pos, arr) => {
                    return arr.map(o => o.articlegroupid).indexOf(group.articlegroupid) === pos;
                });

                // Set article groups
                that.setState({articlegroups: articlegroups})

                let articleGroupIds = [];

                // Find unique group ids
                for (let i in articlegroups) {        // eslint-disable-line no-unused-vars
                    if (articleGroupIds.indexOf(articlegroups[i].articlegroupid) === -1) {
                        articleGroupIds.push(articlegroups[i].articlegroupid);
                    }
                }

                // Fetch all articles by group
                var articleArr = [];

                for (let id in articleGroupIds) {
                    NetworkService.getArticleByArticleGroupId(articleGroupIds[id], that.props.session, function (article) {
                        let certificateid = supplier.articlegroup.find(group => group.articlegroupid === articleGroupIds[id]).certificateid;
                        let certificatename = that.state.certificates.find(certificate => certificate.certificateid === certificateid).name;
                        for (let i in article) {
                            article[i].articlegroupid = articleGroupIds[id];
                            article[i].certificatename = certificatename;
                        }
                        Array.prototype.push.apply(articleArr,article);

                        that.setState({articles:articleArr, articleselection: articleArr})
                    }, function (err) {
                        console.log(err);
                    });
                }

                return supplier;
            })
            .then(function(supplier) {
                return NetworkService.getClaimsBySupplierPromise(supplier.supplierid, that.props.session)
                        .then(function(claims) {
                            supplier.claims = claims;
                            return supplier;
                        });
            })
            .then(function(supplier) {
                // Set supplier selection
                that.setState({ supplierselection: supplier })
            })
            .catch(function (err) {
                console.log(err);
            });
    }   

    _saveDelivery(e){  
        e.preventDefault();                   
        var that =this;       
        let delivery = Object.assign({},that.state.delivery);            
        delivery.registrationdate = moment(delivery.registrationdate).format('YYYY-MM-DD');           
		if(delivery.deliveryid){
			NetworkService.updateDelivery(delivery, that.props.session, function (delivery) {
				that.props.onSave();                 
			}, function (err) {
				console.log(err);
			});
		} else {
			NetworkService.createDelivery(delivery, that.props.session, function (delivery) {
				that.props.onSave();
			}, function (err) {
				console.log(err);
			});
		}
    }
    
    _handleMethodChange(type, val){       
        var that= this;
        let deposit = Object.assign({},that.state.deposit);
        deposit.mixpercent=null;
        deposit.amount=null;
        deposit.cubicmeter=null;
        deposit.numberofarticle=null;
        deposit.articleid=null;
        that.setState({           
            deposit: deposit,                        
            method: val                      
        });
    }

    _handleCubicChange(type, val){  
        var that = this;          
        let deposit = Object.assign({},that.state.deposit);             
        if(val<0){        
            val =0;            
        }
        
        var cubicelem = document.getElementById("cubicelem");
        if(cubicelem){
            if(cubicelem.classList.contains('error')){                
                cubicelem.classList.remove('error');                
            }
        }

        deposit[type] = val;   
        that.setState({
            deposit: deposit           
        }, function(){             
            if(that.state.deposit.cubicmeter && that.state.deposit.mixpercent){
                let deposit = Object.assign({},that.state.deposit);            
                var cal_amt = new Decimal(deposit.mixpercent * deposit.cubicmeter);
                cal_amt = cal_amt.dividedBy(100);             
                deposit.amount = cal_amt.toFixed(5);
                that.setState({deposit:deposit})     
            }                         
        }); 
    }

    _handleNumOfArticleChange(type, val){
        var that=this;   
        let deposit = Object.assign({},that.state.deposit);  
        if(val<0){        
            val =0;            
        } 
        
        var numelem = document.getElementById("numelem");
        if(numelem){
            if(numelem.classList.contains('error')){                
                numelem.classList.remove('error');                
            }
        }
        
        deposit[type] = val;    
        that.setState({ 
            deposit: deposit                                             
        }, function(){
            let deposit = Object.assign({},that.state.deposit);
            if(deposit.mixpercent && that.state.deposit.numberofarticle && that.state.volume){
                var cal_amount = new Decimal(deposit.mixpercent * that.state.volume * that.state.deposit.numberofarticle);
                cal_amount = cal_amount.dividedBy(100);
                deposit.amount = cal_amount.toFixed(5); 
                that.setState({           
                    deposit: deposit,                                           
                });
            }

            if(that.state.volume && that.state.deposit.numberofarticle){
                var cal_cubicmeter = new Decimal(that.state.volume * that.state.deposit.numberofarticle);              
                deposit.cubicmeter = cal_cubicmeter.toFixed(5); 
                that.setState({           
                    deposit: deposit,                                           
                });
            }
        });  
    }

    _handleArticleGroupChange(type, val){ 
        let deposit = Object.assign({},this.state.deposit);             
        deposit[type] = val;
        /*if(val){
            this.createArticleItems();
        }*/
        var filteredArray = [];   

        if(this.state.articles){     
            if(val){
                filteredArray = this.state.articles.filter(a => a.articlegroupid === parseInt(val));
            } else {
                filteredArray = this.state.articles;
            }
        }
        this.setState({
            deposit: deposit,
            articleselection: filteredArray,          
        });
    }

    _clearSelection(){
        this.setState({
            deposit:{
                amount:"",
                mixpercent:"",
                location:"",
                articleid:"",
                claimid:"",
                cubicmeter:"",
                numberofarticle:""
            },
            value: '',
        })
    }

    //Check if deliverynumber is unique in combination with siteid
    _validateNumber(type, value){
        let deliverynumber = "";
        let siteid = "";

        if(type === "siteid" && value && this.state.delivery.deliverynumber){
            deliverynumber = this.state.delivery.deliverynumber;
            siteid = value;
        }

        if(type === "deliverynumber" && value && this.state.delivery.siteid){
            deliverynumber = value;
            siteid = this.state.delivery.siteid;
        }

        var that = this;

        if(uniqueTimeout){
            clearInterval(uniqueTimeout);
        }
        //Timeout to avoid spamming server
        uniqueTimeout = setTimeout(()=>{
            if(deliverynumber && siteid){
                NetworkService.getDeliveryNumberAvailability(deliverynumber, siteid, this.props.session).then(value=>{
                    var delnoel = document.getElementById("delnoel");
                    if(!value.unique){
                        that.setState({codeTaken: true});
                        delnoel.classList.add("error");
                        
                    } else {
                        that.setState({codeTaken: false});
                        delnoel.classList.remove("error");
                    }
                }).catch(err=>{
                    console.log(err);
                })
            }
        },500);
    }

    _addDeposit(){          
        let deposit = Object.assign({},this.state.deposit);
        let delivery = Object.assign({},this.state.delivery);
       
        let containsError = false;
        var artwarn = document.getElementById("artwarn");
        if(!deposit.articleid){ 
            artwarn.classList.add("err-visible");
            containsError = true;
        }else{            
            artwarn.classList.remove("err-visible"); 
        }

        var claimelem = document.getElementById("claimelem");
        if(!deposit.claimid){
            claimelem.classList.add("error");
            containsError = true;
        }else{
            claimelem.classList.remove("error");
        }

        var cubicelem =  document.getElementById("cubicelem");
        if(cubicelem){
            if(!this.state.deposit.cubicmeter){
                cubicelem.classList.add("error");
                containsError = true;
            }else{            
                cubicelem.classList.remove("error");
            }
        }
        
        var numelem =  document.getElementById("numelem");
        if(numelem){
            if(!this.state.deposit.numberofarticle){
                numelem.classList.add("error");  
                containsError = true;
            }else{            
                numelem.classList.remove("error"); 
            }
        }

        var mxprelem = document.getElementById("mxprelem");
        if(!this.state.deposit.mixpercent){           
            mxprelem.classList.add("error");               
            containsError = true;
        }else{            
            mxprelem.classList.remove("error"); 
        }       

        var amtelem = document.getElementById("amtelem");
        if(!this.state.deposit.amount){
            amtelem.classList.add("error");  
            containsError = true;
        }else{            
            amtelem.classList.remove("error"); 
        }

        if(containsError){
            return;
        }

        delivery.deposits.push(deposit);
        this.setState({           
            delivery: delivery            
        });

        //Clear selection after adding deposit
        this._clearSelection();
    }  

    _removeDepositRow(type, item){   
		if(!item.depositid){   
			var arr = this.state.delivery.deposits || [];
			for( var i = 0; i < arr.length; i++){ 
				if ( arr[i] === item) {
				arr.splice(i, 1); 
				}
			}
		
			let delivery = Object.assign({},this.state.delivery);                
			delivery[type] = arr;
			this.setState({
				delivery: delivery           
			});
		} 
    }

    _renderDepositRows(){        
        let rows = [];                    
        var hasAccess =helper.checkAccess(this.props.session.scope, "DELIVERY", 1, 1)   
        if (this.state.delivery.deposits && this.state.delivery.deposits.length > 0) {
        rows =this.state.delivery.deposits.map((item, i) => {                                                     
            return (
                <tr key={i}>                    
                    <td>{item.amount}</td>                        
                    <td>{item.mixpercent}</td>   
                    <td>{item.cubicmeter}</td>                           
                    <td>{item.location}</td>
                    <td>  
                    {hasAccess && !item.depositid ?
                        <button type="button" className="close" onClick={e => this._removeDepositRow("deposits", item)} >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        :null
                    }
                    </td>                           
                </tr>
            )})}
            else{
                return <tr><td colSpan="12">{i18n.t("deposit_message_nodata")}</td></tr>
            }            
        return rows;
    }

    _renderClaimOptions() {
        let items = [];

        items.push(<option key="claimlist" value="">{i18n.t("common_select_claim")}</option>);

        for (let i in this.state.claims) {
            let item = this.state.claims[i];

            items.push(<option key={item.claimid} value={item.claimid}>{item.name}</option>);
        };

        return items;
    }

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        this.state.articleselection.sort(function(artA, artB) {
            var a = artA.articledescription;
            var b = artB.articledescription;

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });

        let sugg = inputLength === 0 ? [] : this.state.articleselection.filter(art =>
            (art.articlenumber + " - " + art.articledescription).toLowerCase().includes(inputValue)
        );

        return sugg.slice(0, 30);
      };

    onChange = (event, { newValue }) => {
        this.setState({
          value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (ev,val) => {
        let selectionstring = val.suggestion.articleid;
        let articlegroupid = val.suggestion.articlegroupid;

        this._handleArticleChange(selectionstring, articlegroupid);
    };

    render() {    
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: i18n.t("autocomplete_placeholder"),
            value,
            onChange: this.onChange
          };
        return (
            <div id="page-content-modal-wrapper">                    
            <div className="panel panel-default">      
                <form onSubmit={e => this._saveDelivery(e)}>        
                    <div className="panel-body">                   
                        <div className="row">
                            <div className="col-md-12">
                                <p>*{i18n.t("delivery_edit_reference_explanation")}</p>
                            </div>
                            <fieldset className="col-md-6">
                                <div className="form-group">
                                    <label>{i18n.t("delivery_common_deliverynumber")} *</label> {this.state.codeTaken?<label className="code-not-unique-warning">{i18n.t("delivery_deliverynumber_taken")}</label>:null}

                                    <input id="delnoel" className="form-control" type="text" value={this.state.delivery.deliverynumber || ""} onChange={e => {this._handleDeliveryChange("deliverynumber", e.target.value);this._validateNumber("deliverynumber",e.target.value)}} disabled={this.state.delivery.deliveryid} required/>
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_supplier")}</label>                               
                                    <select name="select" className="form-control" value={this.state.delivery.supplierid || ""} onChange={e => this._handleSupplierChange(e.target.value)} disabled={this.state.delivery.deliveryid} required>
                                        {this.createSupplierItems()}
                                    </select>                               
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_site")}</label>                               
                                    <select name="select" className="form-control" value={this.state.delivery.siteid || ""} onChange={e => {this._handleDeliveryChange("siteid", e.target.value);this._validateNumber("siteid",e.target.value)}}  disabled={this.state.delivery.deliveryid} required>
                                        {this.createSiteItems()}
                                    </select>                               
                                </div>                                
                            </fieldset>  
                            <fieldset className="col-md-6"> 
                                <div className="form-group">
                                    <label>{i18n.t("delivery_common_reference")} *</label>
                                    <input className="form-control" type="text" value={this.state.delivery.reference || ""} onChange={e => this._handleDeliveryChange("reference", e.target.value)} required/>
                                </div>                                
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_registrationdate")}</label>
                                    <div>
                                        <DatePicker className={"form-control"} dateFormat="yyyy-MM-dd" selected={this.state.delivery.registrationdate ? moment(this.state.delivery.registrationdate).toDate() : null} value={moment(this.state.delivery.registrationdate).toDate()}  onChange={this._handleRegistrationDateChange} required/>
                                    </div>                                
                                </div>
                            </fieldset>                             
                        </div>
                        <label><strong>{i18n.t("common_deposit")}</strong></label>
                        <div className="modal-footer pb-0"></div>   
                        <div className="row">                            
                            <fieldset className="col-md-6">
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_calcreditby")}</label>    
                                    <select className="form-control" value={this.state.method}  onChange={e => this._handleMethodChange("method", e.target.value)}>
                                        {/*<option value="directamount">{i18n.t("deposit_select_direct")}</option>*/}
                                        <option value="cubicmeter">{i18n.t("deposit_common_cubicmeter")}</option>
                                        <option value="article">{i18n.t("deposit_select_article")}</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_articlegroup")}</label>                               
                                    <select name="select" id="artgroupelem" className="form-control" value={this.state.deposit.articlegroupid} onChange={e => this._handleArticleGroupChange("articlegroupid", e.target.value)} >
                                        {this.createArticleGroupItems()}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_article")}</label>
                                    <p>{this.state.articleselection.length} {i18n.t("deposit_common_articles")}</p>
                                    <p id="artwarn" className="artwarn">{i18n.t("deposit_articles_no_selection")}</p>
                                    {this.state.articleselection.length > 25?
                                    <Autosuggest
                                        theme={{input:"form-control"}}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                    />
                                    :
                                    <select name="select" id="artelem" className="form-control" value={this.state.deposit.articleid || ""} onChange={e => this._handleArticleChange(e.target.value)} >
                                        {this.createArticleItems()}
                                    </select>}
                                    {this.state.deposit.articleid && this.state.volume <=0 && this.state.method ==="article" && <label className="warning badge-warning mt-2 ">{i18n.t("deposit_common_article_alert")}</label>}
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_claim")}</label>
                                    <select className="form-control" id="claimelem" value={this.state.deposit.claimid} onChange={e => this._handleChange('claim', e.target.value)}>
                                        {this._renderClaimOptions()}
                                    </select>
                                </div>
                                { this.state.method ==="article" && this.state.volume >0 &&
                                    <div className="form-group">
                                        <label>{i18n.t("deposit_common_noofarticle")}</label>
                                        <input className="form-control" id="numelem" type="number" value={this.state.deposit.numberofarticle || ""}  onChange={e => this._handleNumOfArticleChange("numberofarticle", e.target.value)} />
                                    </div>
                                }    
                                { this.state.method ==="article" ? 
                                    <div className="form-group">
                                        <label>{i18n.t("deposit_common_cubicmeter")}</label>
                                        <label className="form-control">{this.state.deposit.cubicmeter || ""} </label>
                                    </div> 
                                    :
                                    <div className="form-group">
                                        <label>{i18n.t("deposit_common_cubicmeter")}</label>
                                        <input className="form-control" id="cubicelem" type="number" value={this.state.deposit.cubicmeter || ""} onChange={e => this._handleCubicChange("cubicmeter", e.target.value)} />
                                    </div>
                                }
                                                           
                            </fieldset>
                            <fieldset className="col-md-6">
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_mixpercent")}</label>
                                    <input className="form-control" id="mxprelem" type="number" min="70" max="100"  value={this.state.deposit.mixpercent || ""} onChange={e => this._handleChange("mixpercent", e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_amount")}</label>
                                    <label id="amtelem" className="form-control" >{this.state.deposit.amount}</label>
                                    {/*<input className="form-control" id="amtelem" type="number" step="any" value={this.state.deposit.amount || ""} onChange={e => this._handleChange("amount", e.target.value)} />*/}
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_location")}</label>
                                    <input className="form-control" type="text" value={this.state.deposit.location || ""} onChange={e => this._handleChange("location", e.target.value)} />
                                </div>
                            </fieldset>
                            <fieldset className="col-md-12 ">
                                <button className="btn btn-primary" type="button" onClick={e => this._addDeposit()}>{i18n.t("deposit_button_add")}</button>                       
                            </fieldset>
                        </div>
                        <table className="table table-striped mt-4 table-responsive-sm">                        
                            <thead>
                                <tr>
                                    <th>{i18n.t("deposit_common_amount")}</th> 
                                    <th>{i18n.t("deposit_common_mixpercent")}</th> 
                                    <th>{i18n.t("deposit_common_cubicmeter")}</th>                           
                                    <th>{i18n.t("deposit_common_location")}</th>  
									<th></th>                                                                    
                                </tr>
                            </thead>
                            <tbody>                   
                            {
                                this._renderDepositRows()                            
                            }                    
                            </tbody>                
                    </table> 
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit" disabled={this.state.delivery.deposits.length <= 0}>{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-danger" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div> 
                </form>  
                </div>                             
            </div>
       
        );
    }
};

export default connect(mapStateToProps)(EditDelivery);
